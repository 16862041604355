import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Template/Navbar";
import "../Style/ContactPage.css";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:mysnaptikvideo@gmail.com?subject=Kontak%20dari%20${name}%20(${email})&body=${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>Hubungi Kami - My SnapTik Video</title>
        <meta name="description" content="Hubungi kami untuk informasi lebih lanjut atau pertanyaan tentang layanan kami." />
        <meta name="keywords" content="kontak, hubungi, layanan, snap, tiktok, video, downloader tiktok" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* Anda bisa menambahkan tag meta lainnya di sini */}
      </Helmet>
      <div className="contact-form-container">
        <Navbar />
        <h1 className="contact-heading">Hubungi Kami</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nama:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Pesan:</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Kirim
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
