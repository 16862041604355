import React, { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function Navbar() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className="navbar-item logo" style={{ display: "flex", alignItems: "center", marginBottom: "0px", position: "relative" }}>
      <a href="https://mysnaptik.xyz/" className="fs24 fw700" title="MyTik-Video" style={{ color: "#000", fontSize: "24px", fontWeight: "700", textDecoration: "none", marginBottom: "5px", marginTop: "0px", fontStyle: "italic" }}>
        &nbsp;&nbsp;My<span style={{ color: "#0075ff" }}>SNAPTIK-</span><span style={{ color: "#000" }}>Video</span>
      </a>
      {/* <div style={{ fontSize: "10px" }}> 1.0.1 </div> */}

      <div style={{ position: "absolute", right: "20px", marginTop: "-15px" }}>
        <br />
        <button onClick={handleDrawerOpen} className="menu-button">
          <MenuIcon />
        </button>
      </div>

       <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <div role="presentation" onClick={handleDrawerClose} onKeyDown={handleDrawerClose} style={{ display: 'flex', flexDirection: 'column', width: '200px' }}>
          <List>
            <ListItem button component="a" href="/">
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component="a" href="/contact">
              <ListItemText primary="Contact" />
            </ListItem>
            <ListItem button component="a" href="/about">
              <ListItemText primary="About us" />
            </ListItem>
            <ListItem button component="a" href="/termsandcodition">
              <ListItemText primary="Terms and Condition" />
            </ListItem>
            <ListItem button component="a" href="/privacypolicy">
              <ListItemText primary="Privacy Policy" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
}

export default Navbar;
