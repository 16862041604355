import React from "react";

function Footer() {
  return (
    <div style={{ textAlign: "left", margin: "auto", display: "flex", flexDirection: "column", alignItems: "center", paddingLeft: "10px", background: "#E7F1F1" }}>
      <h3>Unduh Video TikTok (dulu Musically) Tanpa Watermark secara GRATIS</h3>
      <p style={{marginTop : '0px', fontSize: "14px"}}>
      MyTik-Video adalah salah satu alat online terbaik yang tersedia untuk mengunduh video TikTok tanpa watermark.  tidak perlu menginstal perangkat lunak apa pun di komputer atau ponsel . Yang  butuhkan hanyalah tautan video TikTok, dan semua pemrosesan dilakukan di sisi kami, jadi  hanya beberapa klik saja dari mengunduh video ke perangkat .
      </p>

      <h3 style={{marginTop: "0px"}}>Fitur Utama:</h3>
      <ul style={{marginTop: "0px", fontSize: "14px"}}>
        <li >
        Tanpa watermark untuk kualitas yang lebih baik, fitur yang kebanyakan alat lain tidak bisa menyediakannya.
        </li>
        <li>
        Unduh video TikTok, video Musically di perangkat apa pun yang  inginkan: ponsel, PC, atau tablet. TikTok hanya memungkinkan pengguna mengunduh video melalui aplikasinya, dan video yang diunduh dilengkapi dengan watermark.
        </li>
        <li>
        Unduh menggunakan peramban web : Kami bertujuan untuk membuat semuanya sederhana bagi . Tidak perlu mengunduh atau menginstal perangkat lunak apa pun. Kami juga memiliki aplikasi untuk tujuan ini, tetapi  hanya dapat menginstalnya jika  menginginkannya.
        </li>
        <li>
        Selalu gratis. Kami hanya menampilkan beberapa iklan untuk mendukung pemeliharaan layanan kami dan pengembangan lebih lanjut.

        </li>
      </ul>

      <p style={{marginTop : '0px', fontSize: "14px", paddingLeft: "5px"}}>
      MyTik-Video baru menyediakan pengguna dengan kemampuan untuk mengunduh slideshow foto TikTok sebagai format Video Mp4. Gambar dan musik dalam slideshow Tiktok secara otomatis digabungkan oleh MyTik-Video. Selain itu,  juga dapat mengunduh setiap gambar dalam slideshow ke komputer  segera
      </p>

      <h3 style={{marginTop: "0px"}}>Cara Mengunduh video Tiktok tanpa watermark:</h3>
      <ul style={{marginTop: "0px", fontSize: "14px"}}>
        <li>Buka aplikasi Tik Tok di ponsel /atau Web di peramban .</li>
        <li>Pilih video apa pun yang ingin  unduh.</li>
        <li>Klik tombol Bagikan di kanan bawah.</li>
        <li>Klik tombol Salin Tautan.</li>
        <li>
        Unduh menggunakan peramban : Saya ingin membuat semuanya sederhana bagi . Tidak perlu mengunduh atau menginstal perangkat lunak apa pun. Saya membuat aplikasi untuk tujuan ini juga tetapi  hanya dapat menginstalnya kapan pun  suka.
        </li>
        <li>
        Kembali ke MyTIK-Video.App dan tempelkan tautan unduhan  ke kolom di atas, lalu klik tombol Unduh.
        </li>
        <li>
        Tunggu server kami melakukan tugasnya dan kemudian, simpan video ke perangkat .
        </li>
      </ul>
    </div>
  );
}

export default Footer;
