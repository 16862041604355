import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Homepage from "./Pages/Homepage.jsx";
import TermsAndCondition from "./Pages/TermsAndCodition.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy.jsx";
import ContactPage from "./Pages/Contact.jsx";
import About from "./Pages/About.js";
// import ReactGA from 'react-ga';


function App() {

  // send report to google analytics
  // ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Router>
      <div>
        {/* <Header /> */}
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/termsandcodition" element={<TermsAndCondition />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/contact" element={<ContactPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
