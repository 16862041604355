import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Template/Footer";
import Navbar from "../Template/Navbar";
import "../Style/Homepage.css"; // Import file CSS yang baru dibuat
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faCirclePlay,
  faCommentDots,
  faFileText,
  faIdCard,
  faTimesRectangle,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { Helmet } from "react-helmet";

function Homepage() {
  const navigate = useNavigate();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [videoHD, setVideoHD] = useState("");
  const [soundVideo, setSoundVideo] = useState("");
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [showDownloadProgress, setShowDownloadProgress] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [result, setResult] = useState([]);

  const handlePasteClick = () => {
    navigator.clipboard.readText().then((text) => {
      setIsPressed(true);
      setLink(text);
      setTimeout(() => {
        setIsPressed(false);
      }, 200);
      // handleGenerateClick(); // Memanggil handleGenerateClick() setelah nilai link diatur
    });
  };

  // GENERATE
  const handleGenerateClick = async () => {
    try {
      setIsDownloading(true);
      let domain = "https://www.tikwm.com/";
      let response = await axios.post(domain + "api/", null, {
        headers: {
          accept: "application/json, text/javascript, */*; q=0.01",
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "sec-ch-ua":
            '"Chromium";v="104", " Not A;Brand";v="99", "Google Chrome";v="104"',
          "user-agent":
            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36",
        },
        params: {
          url: link,
          count: 12,
          cursor: 0,
          web: 1,
          hd: 1,
        },
      });
      let resultData = response.data.data;
      setResult(resultData);
      setCoverImage(domain + response.data.data.cover);
      setVideoHD(domain + response.data.data.hdplay);
      console.log("setVideoHD", response.data.data.hdplay);
      // judul
      const url = response.data.data.hdplay;
      const lastSlashIndex = url.lastIndexOf("/");
      const filename = url.substring(lastSlashIndex + 1); // Mengambil substring setelah tanda '/' terakhir
      console.log(filename); // Output: 7305703263653874949.mp4
      setTitle(filename);
      setSoundVideo(domain + response.data.data.music);
    } catch (error) {
      console.error("Kesalahan saat membuat konten:", error);

      // Menampilkan notifikasi kesalahan kepada pengguna
      alert(
        "URL tidak valid atau tidak dapat diakses. Periksa kembali URL yang dimasukkan."
      );

      // Membersihkan nilai state
      setTitle("");
      setCoverImage("");
      setVideoHD("");
      setSoundVideo("");
    } finally {
      setIsDownloading(false);
    }
  };

  const handleVideoHDDownload = () => {
    setShowDownloadProgress(true);

    if (videoHD) {
      // Mengambil URL video HD
      const videoHdUrl = videoHD;
      console.log("videoHdUrl:", videoHdUrl);

      // Memulai pengunduhan video menggunakan fetch
      fetch(videoHdUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Gagal mengunduh video HD");
          }

          // Mendapatkan "content-length" dari header respons
          const contentLength = response.headers.get("content-length");
          const total = parseInt(contentLength, 10);
          let loaded = 0; // Jumlah data yang sudah terunduh

          // Menggunakan ReadableStream untuk mengambil data secara berurutan
          const reader = response.body.getReader();
          console.log("reader:", reader);

          // Fungsi untuk mengupdate progress unduhan
          const updateProgress = (progress) => {
            // Hitung persentase
            loaded += progress;
            const percentComplete = (loaded / total) * 100;
            setDownloadProgress(percentComplete);
          };

          return new Response(
            new ReadableStream({
              start(controller) {
                const read = () => {
                  reader.read().then(({ value, done }) => {
                    if (done) {
                      // Unduhan selesai
                      console.log("Unduhan selesai");
                      controller.close();
                    } else {
                      updateProgress(value.byteLength);
                      controller.enqueue(value);
                      read();
                    }
                  });
                };
                read();
              },
            })
          );
        })
        .then((stream) => {
          return stream.blob();
        })
        .then((blob) => {
          // Membuat URL objek dari blob
          const url = URL.createObjectURL(blob);

          // Membuat anchor element
          const link = document.createElement("a");
          link.href = url;

          // Memeriksa apakah title memiliki nilai yang benar
          const sanitizedTitle = title
            .trim()
            .substring(0, 15)
            .replace(/[^\w\s]/gi, " ");
          const defaultFilename = "mysnaptik.mp4"; // Default filename dengan ekstensi .mp4
          const filename = sanitizedTitle
            ? sanitizedTitle + ".mp4"
            : defaultFilename;

          link.download = filename;
          console.log("test-title", filename);

          document.body.appendChild(link);
          link.click();

          // Membersihkan URL objek setelah pengunduhan selesai
          URL.revokeObjectURL(url);

          // Menghapus elemen anchor setelah proses unduhan selesai
          document.body.removeChild(link);
        })

        .catch((error) => {
          console.error("Kesalahan saat mengunduh video HD:", error);
        });
    }
  };

  const handleSoundDownload = () => {
    setShowDownloadProgress(true);

    if (soundVideo) {
      // Mengambil URL suara
      const soundUrl = soundVideo;

      // Memulai pengunduhan suara menggunakan fetch
      fetch(soundUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Gagal mengunduh suara");
          }

          // Mendapatkan "content-length" dari header respons
          const contentLength = response.headers.get("content-length");
          console.log("Panjang konten:", contentLength);
          const total = parseInt(contentLength, 10);
          let loaded = 0; // Jumlah data yang sudah terunduh

          // Menggunakan ReadableStream untuk mengambil data secara berurutan
          const reader = response.body.getReader();

          // Fungsi untuk mengupdate progress unduhan
          const updateProgress = (progress) => {
            // Hitung persentase
            loaded += progress;
            const percentComplete = (loaded / total) * 100;
            // console.log(
            //   "Download Progress: " + percentComplete.toFixed(2) + "%"
            // );
            // Di sini Anda dapat memperbarui UI Anda dengan persentase unduhan
            setDownloadProgress(percentComplete);
          };

          return new Response(
            new ReadableStream({
              start(controller) {
                const read = () => {
                  reader.read().then(({ value, done }) => {
                    if (done) {
                      // Unduhan selesai
                      console.log("Unduhan selesai");
                      controller.close();
                    } else {
                      updateProgress(value.byteLength);
                      controller.enqueue(value);
                      read();
                    }
                  });
                };
                read();
              },
            })
          );
        })
        .then((stream) => {
          return stream.blob();
        })
        .then((blob) => {
          // Membuat URL objek dari blob
          const url = URL.createObjectURL(blob);

          // Membuat anchor element
          const link = document.createElement("a");
          link.href = url;
          link.download =
            title.trim() !== ""
              ? title.trim() + ".mp3"
              : "my-snaptik-video.mp3";
          document.body.appendChild(link);
          link.click();

          // Membersihkan URL objek setelah pengunduhan selesai
          URL.revokeObjectURL(url);

          // Menghapus elemen anchor setelah proses unduhan selesai
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Kesalahan saat mengunduh suara:", error);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Downloader TikTok Terbaik Gratis - My SnapTik Video</title>
        <meta
          name="description"
          content="Dapatkan unduhan berkualitas HD dari TikTok dengan downloader terbaik dan gratis."
        />
        <meta
          name="keywords"
          content="downloader tiktok, unduhan tiktok, video tiktok, snaptik, snaptiktok, layanan unduhan, snap, tiktok, video, kontak, hubungi"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div>
        <Navbar />
        <div
          style={{
            textAlign: "center",
            background: "#68A4EC",
            borderRadius: "15px",
          }}
        >
          <h1
            style={{
              color: "#fff",
              fontSize: "30px",
              lineHeight: "29px",
              marginTop: "5px",
              marginBottom: "0px",
              textShadow: "2px 2px 4px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
            }}
          >
            Download Video TikTok
          </h1>
          <h2
            style={{
              color: "#fff",
              fontSize: "16px",
              lineHeight: "29px",
              marginTop: "0px",
              marginBottom: "12px",
              textShadow: "2px 2px 4px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
            }}
          >
            Tanpa Watermark, Cepat untuk semua perangkat
          </h2>

          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Tekan dan TEMPEL link disini"
            style={{
              width: "calc(80vw - 30px)", // Lebar dikurangi untuk menampung tombol X
              borderRadius: "8px",
              padding: "10px",
              border: "1px solid #334B6B",
              fontSize: "16px",
              outline: "none",
              boxShadow: "0 0 5px rgba(53, 125, 218, 0.5)",
              fontStyle: "italic",
              marginRight: "1px", // Jarak antara teks field dan tombol X
            }}
          />
          {link && ( // Tombol X hanya muncul jika ada nilai pada teks field
            <button
              onClick={() => setLink("")} // Fungsi untuk menghapus nilai pada teks field
              style={{
                border: "1px solid #ccc", // Menambahkan border agar terlihat seperti tombol
                backgroundColor: "#f0f0f0", // Warna latar belakang untuk menyerupai tombol
                color: "#333", // Warna teks agar kontras dengan latar belakang
                fontSize: "16px",
                padding: "8px 16px", // Menambahkan padding agar lebih terlihat seperti tombol
                borderRadius: "4px", // Mengubah sudut menjadi bulat agar terlihat lebih halus
                cursor: "pointer",
                outline: "none", // Menghilangkan outline pada saat di-focus
                boxShadow: "1px 1px 2px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
                textShadow: "1px 1px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
              }}
            >
              CLEAR
            </button>
          )}

          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={handlePasteClick}
              style={{
                margin: "5px",
                padding: "8px 12px 8px 14px",
                background: "#dae4ff",
                borderRadius: "6px",
                border: "none",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#535DBB",
                boxShadow: "1px 1px 2px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
                textShadow: "1px 1px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
              }}
            >
              PASTE LINK
            </button>
            <button
              onClick={handleGenerateClick}
              style={{
                margin: "5px",
                padding: "8px 12px 8px 14px",
                background: "#dae4ff",
                borderRadius: "6px",
                border: "none",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#00be63",
                boxShadow: "1px 1px 2px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
                textShadow: "1px 1px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
              }}
            >
              DAPATKAN VIDEO HD
            </button>
          </div>
          <br />
          {videoHD && (
            <button
              onClick={handleVideoHDDownload}
              style={{
                margin: "10px",
                padding: "10px 20px", // Menambahkan padding untuk ukuran tombol
                borderRadius: "5px", // Memberikan radius sudut tombol
                background: "#2eb811", // Warna latar belakang
                color: "#fff", // Warna teks
                border: "none", // Menghilangkan garis tepi
                cursor: "pointer", // Mengubah ikon kursor saat mengarahkan ke tombol
                fontSize: "16px", // Ukuran teks
                boxShadow: "1px 1px 2px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
              }}
            >
              DOWNLOAD HIGH DEFINITION VIDEO
            </button>
          )}

          {soundVideo && (
            <button
              onClick={handleSoundDownload}
              style={{
                margin: "10px",
                padding: "10px 20px", // Menambahkan padding untuk ukuran tombol
                borderRadius: "5px", // Memberikan radius sudut tombol
                background: "#020d18", // Warna latar belakang
                color: "#fff", // Warna teks
                border: "none", // Menghilangkan garis tepi
                cursor: "pointer", // Mengubah ikon kursor saat mengarahkan ke tombol
                fontSize: "16px", // Ukuran teks
                boxShadow: "1px 1px 2px #000", // Efek bayangan: horizontal-offset vertical-offset blur-radius color
              }}
            >
              DOWNLOAD HIGH QUALITY AUDIO
            </button>
          )}

          {/* condition to show and hiden  */}
          {showDownloadProgress && downloadProgress < 100 ? (
            <div
              id="downloadProgress"
              style={{
                marginTop: "5px",
                padding: "10px",
                background: "#FF000000;", // Warna latar belakang hijau
                color: "#000000", // Warna teks putih
                borderRadius: "5px", // Radius sudut
                textAlign: "center", // Teks di tengah
                fontSize: "16px", // Ukuran teks
                fontWeight: "bold",
              }}
            >
              Progress: {downloadProgress.toFixed(2)}%
            </div>
          ) : null}

          <br />
          {coverImage && (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                maxWidth: "90%", // Memperbesar lebar maksimum
                margin: "0 auto", // Menengahkan elemen di dalam wadah
              }}
            >
              <div
                style={{
                  flex: "1",
                  position: "relative",
                  marginRight: "10px", // Mengurangi jarak antara gambar dan informasi
                }}
              >
                {isDownloading && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(255, 255, 255, 0.8)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="loader"></div>
                  </div>
                )}
                <img
                  src={coverImage}
                  alt="Cover Tiktok"
                  style={{
                    width: "100%", // Memastikan gambar coverImage memenuhi lebar wadah
                    maxHeight: "400px", // Meningkatkan maksimum tinggi gambar
                    objectFit: "contain",
                    marginTop: "5px",
                    borderRadius: "15px", // Menambahkan radius sudut ke gambar
                  }}
                />
              </div>
              <div
                style={{
                  flex: "1",
                  marginTop: "10px",
                  textAlign: "left",
                  color: "#fff", // Warna teks putih
                  fontSize: "14px", // Mengurangi ukuran tulisan
                }}
              >
                <div>
                  {/* <p>ID: {result.author.unique_id}</p> */}
                  <p>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: "5px" }}
                    />
                    {result.author.unique_id}
                  </p>

                  <p>
                    <FontAwesomeIcon
                      icon={faCommentDots}
                      style={{ marginRight: "5px" }}
                    />
                    {result.comment_count}{" "}
                  </p>

                  <p>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleDown}
                      style={{ marginRight: "5px" }}
                    />
                    {result.download_count}{" "}
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCirclePlay}
                      style={{ marginRight: "5px" }}
                    />
                    {result.play_count}{" "}
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faFileText}
                      style={{ marginRight: "5px" }}
                    />
                    {result.title.length > 50
                      ? result.title.substring(0, 50) + "..."
                      : result.title}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* footer */}
        <Footer />

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "20px",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <div>
            <a
              href="//www.dmca.com/Protection/Status.aspx?ID=55ffd6be-d56e-4416-a5d8-71f8781b12a8"
              title="DMCA.com Protection Status"
              className="dmca-badge"
            >
              {" "}
              <img
                src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=55ffd6be-d56e-4416-a5d8-71f8781b12a8"
                alt="DMCA.com Protection Status"
                style={{ width: "100px", height: "auto" }}
              />
            </a>{" "}
            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
              {" "}
            </script>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "0px",
            fontFamily: "Arial, sans-serif",
            fontSize: "10px",
          }}
        >
          Version 0.1.8
        </div>
      </div>
    </>
  );
}

export default Homepage;
