// TermsAndCondition.js
import React from "react";
import Navbar from "../Template/Navbar";
import { Helmet } from "react-helmet";

const TermsAndCondition = () => {
  return (
    <>
      <Helmet>
        <title>Hubungi Kami - My SnapTik Video</title>
        <meta name="description" content="Halaman Terms and Condition." />
        <meta
          name="keywords"
          content="kontak, hubungi, layanan, snap, tiktok, video, downloader tiktok"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* Anda bisa menambahkan tag meta lainnya di sini */}
      </Helmet>
      <div>
        <Navbar />

        <h1>Terms of Service - MyTik-Video™</h1>
        <p>
          Please read these terms of service (Terms of Service or ToS) carefully
          before accessing our application and website.
        </p>

        <p>
          Our ToS may change from time to time to be in line with the
          developments to our application and website to give our valued users
          the highest satisfaction. Please check regularly the update time on
          top of the ToS to ensure you have read the updated version with
          changes.
        </p>

        <h2>Acceptance of Terms of Service</h2>
        <p>
          By accessing and using our application and website, you signify your
          acceptance of our policy and ToS, including the updated versions. If
          you do not agree with any content, please do not use our application
          and website.
        </p>

        <h2>Who may use our Services (User)</h2>
        <p>
          Our ToS governs the relationship between MyTik-Video application
          (MyTik-Video App) and users of MyTik-Video App being who access the
          MyTik-Video App and/or use the products and services provided through
          the MyTik-Video App (MyTik-Video Products and Services).
        </p>

        <p>
          Our ToS is a mutual agreement between you, either an individual or an
          entity (You or User) or a group of individuals or entities (You or
          Users), and the developer of MyTik-Video Products and Services
          (MyTik-Video) regarding the use of MyTik-Video Products and Services.
        </p>

        <p>
          By accessing or using MyTik-Video Products and Services, you confirm
          that you have carefully read, understood, and accepted our ToS, and
          you agree to abide by the ToS as a User/Users.
        </p>

        <h2>Materials relate to our Services</h2>
        <p>
          MyTik-Video creates and develops content, information, graphics,
          documents, text, products, and all other elements and products and
          services offered through MyTik-Video App (Content or Materials)
          available for your use for free and personal use subject to the terms
          and conditions set out in this document.
        </p>

        <p>
          By accessing and using MyTik-Video App, you agree to be bound by and
          to accept our ToS and all terms and conditions contained and/or
          referenced herein, and you agree to abide by the ToS as a User/Users
          of our Content and Materials.
        </p>

        <h2>Users' Obligations</h2>
        <p>
          You agree to use MyTik-Video App, MyTik-Video Products and Services
          only for purposes permitted by our ToS as well as any applicable law,
          regulation or generally accepted practices or guidelines in the
          relevant jurisdictions.
        </p>

        <p>
          MYTIK-VIDEO IS NOT RESPONSIBLE FOR ANY VIOLATION OF APPLICABLE LAWS,
          RULES, OR REGULATIONS COMMITTED BY YOU OR A THIRD PARTY AT YOUR
          BEHEST.
        </p>

        <p>
          Specifically, you agree and warrant that in using MyTik-Video App,
          MyTik-Video Products and Services, your actions do not contravene the
          laws, rules, or regulations of (1) the country, state, or locality
          where you reside, or (2) the country, state, or locality where
          MyTik-Video is located or operates.
        </p>

        <h2>Intellectual Property</h2>
        <h3>Trademark</h3>
        <p>
          MyTik-Video respects trademark rights and complies with trademark laws
          and regulations. Our trademark MyTik-Video™ is created by MyTik-Video
          and should be used only for and in relation to our MyTik-Video
          Products and Services and MyTik-Video App.
        </p>

        <p>
          Use the exact format MyTik-Video™ as a word without any space,
          division, or separation among its constituent components or in any
          other variants.
        </p>

        <p>
          Never associate our trademark MyTik-Video™ with and never confuse our
          MyTik-Video™ with any other individual(s), entity(ies),
          application(s), website(s), goods or services of other individuals and
          entities if not belong to MyTik-Video.
        </p>

        <p>
          We reserve our rights in and relating to our trademark MyTik-Video™
          against any misuse, misrepresentation, or infringement in any form.
        </p>

        <p>
          We encourage and thank you who inform us of any suspect misuse,
          misrepresentation, infringement, and non-compliance for our proper
          handling.
        </p>

        <h3>Copyright</h3>
        <p>
          MyTik-Video respects copyrights and complies with copyright laws and
          regulations.
        </p>

        <p>
          Users are responsible for the content you use with (the link you paste
          on) MyTik-Video App.
        </p>

        <p>
          MyTik-Video provides support to Users to download information/content
          Users paste on MyTik-Video App and Web for the legitimate interests of
          the Users.
        </p>

        <p>
          We only display "original" content that users have posted on websites,
          pages or networks of service or social network providers. Users are
          responsible for ensuring they have the legal right in accordance with
          relevant laws and ensure their own intellectual property rights in
          relation to the content as posted.
        </p>

        <p>
          Users only use video downloaded through the MyTik-Video App for
          personal, non-commercial, free purposes.
        </p>

        <p>
          MyTik-Video cannot and has no obligation to check whether each
          content/information is legal or not, but if it detects any violation,
          it will ban Users from accessing and using MyTik-Video App and
          MyTik-Video Products and Services.
        </p>

        <p>
          MyTik-Video does not backup/archive Users’ content for any reason.
        </p>

        <p>
          We encourage and thank you who inform us of any suspect misuse,
          misrepresentation, unauthorized use, infringement and non-compliance
          for our proper handling.
        </p>

        <h3>Use License</h3>
        <p>
          We grant our Users permission to temporarily download one copy of the
          Content and Materials (as defined above) on MyTik-Video App for
          personal, non-commercial transitory viewing only. This is the grant of
          a license, not a transfer of title, and under this license you may
          not:
        </p>

        <p>Alter, modify, distribute or duplicate the Materials;</p>

        <p>
          Use the Content and Materials for any commercial purpose, or for any
          public display (commercial or non-commercial);
        </p>

        <p>
          Remove any copyright or other proprietary notations from the Content
          and Materials; or
        </p>

        <p>
          Transfer the Content and Materials to another person or "mirror" the
          Content and Materials on any other server.
        </p>

        <h3>Disclaimer</h3>
        <p>
          We respect and assist Users in protecting their legitimate rights of
          attribution and integrity in works and contents. The Content and
          Materials on MyTik-Video App are provided to serve the interest of the
          Users who are the legitimate owners or authors. MyTik-Video makes no
          warranties, expressed or implied, and hereby disclaims and negates all
          other warranties, including without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property or other violation of
          rights. Further, MyTik-Video does not warrant or make any
          representations concerning the accuracy, likely results, or
          reliability of the use of the Content and Materials on its website or
          otherwise relating to such Content and Materials or on any sites
          linked to the site.
        </p>

        <h2>Privacy</h2>
        <p>
          We run MyTik-Video App in compliance with our set principles to ensure
          that the confidentiality of personal information including information
          of our Users is protected and maintained without disclosing to any
          third parties for any reason.
        </p>

        <h2>Accuracy of Content and Materials</h2>
        <p>
          The Content and Materials appearing on MyTik-Video App could include
          technical, typographical, or photographic errors. MyTik-Video does not
          warrant that any of the Content and Materials are accurate, complete,
          or current. MyTik-Video may make changes to the Content and Materials
          contained on its website at any time without notice.
        </p>

        <h2>Links</h2>
        <p>
          MyTik-Video has no responsibility for any of the sites linked to its
          website, together with the contents thereof. The inclusion of any link
          does not imply endorsement by MyTik-Video of the site. Use of any such
          linked website is at the Users' own risk.
        </p>

        <h2>Terms of Use - Modifications</h2>
        <p>
          These Terms of Service may be amended by MyTik-Video at any time upon
          notice provided by any of the following means: through a posting on
          the main page of the Website, when or after you login into your User
          Account (as defined below), or by e-mail to the address you provided
          when you set up your User Account. We always add information on the
          updated version of the Terms of Service on the top of the terms.
          Therefore, you are responsible for reading the updated terms every
          time you access/use our Services and ensure you are fully aware of the
          terms/updates before using our Services. Your failure to provide or
          maintain accurate or current contact information in your User Account
          will not obviate your responsibility to comply with these Terms of
          Service as amended from time to time. Using contact details or
          identifications of others is not allowed and is considered fraud. We
          will deactivate your account at any time we identify your fraud.
        </p>
      </div>
    </>
  );
};

export default TermsAndCondition;
