import React from "react";
import Navbar from "../Template/Navbar";
import { Helmet } from "react-helmet";


// Define the PrivacyPolicy component
class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Hubungi Kami - My SnapTik Video</title>
          <meta
            name="description"
            content="Privacy Policy downloader video tiktok."
          />
          <meta
            name="keywords"
            content="kontak, hubungi, layanan, snap, tiktok, video, downloader tiktok"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          {/* Anda bisa menambahkan tag meta lainnya di sini */}
        </Helmet>
        <div>
          <Navbar />

          <h2>Privacy Policy - MyTik-Video</h2>
          <h3>Personal Identification Information</h3>
          <p>
            Users can visit the MyTik-Video App anonymously. We never record
            user identification information and will only collect personal
            identification information from users if they voluntarily submit
            such information to us. Users can always refuse to supply personal
            identification information. However, if they agree to provide the
            information, they are responsible for providing accurate and correct
            identification information about themselves. MyTik-Video is not
            responsible for any fake or incorrect information provided by users.
            If we discover such incidents, we will ban users from accessing and
            using the MyTik-Video App and our services.
          </p>

          <h3>Advertising</h3>
          <p>
            We accept advertisements (Ads) on the MyTik-Video App to maintain
            and support our research and development on the MyTik-Video App for
            non-commercial purposes. Ads appearing on the MyTik-Video App may be
            delivered to users by advertising partners who may set cookies. They
            will only compile non-personal identification information about you
            or others who use your computer and do not track personal
            information about you, such as your name, email address, and
            physical address. You may dismiss the use of cookies or cease access
            to our application and website at any time as MyTik-Video users are
            not required to accept the Ads.
          </p>

          <h3>Changes to This Privacy Policy</h3>
          <p>
            MyTik-Video has the discretion to update this privacy policy at any
            time. When we do, we will post a notification on the main page of
            the MyTik-Video App and revise the updated date at the top of this
            page. We encourage users to frequently check this page for any
            changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </p>

          <h3>Your Acceptance of These Terms</h3>
          <p>
            By accessing and using the MyTik-Video App, you express your
            voluntary acceptance of this policy. If not, please do not use our
            services. Your continued use of the services following the posting
            of changes to this policy will be deemed your acceptance of those
            changes.
          </p>
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
